import axiosInstance from "utils/axiosInstance";

const API_URL = 'https://erp.doktorsjouren.se/api/';

const getReferenceQuestion = (id) =>
  axiosInstance
    .get(
      API_URL +
        "method/erpnext.healthcare.doctype.reference_question.reference_question.get_questions?unique_id=" +
        id
    )
    .then((resp) => resp.data.message);

const saveReferenceAnswer = (answers) => {
  let data = {
    data: answers,
  };
  console.log("data ", data);
  return axiosInstance
    .post(
      API_URL +
        "method/erpnext.healthcare.doctype.healthcare_practitioner.healthcare_practitioner.save_reference_answers",
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return JSON.stringify(error);
    });
};
const referenceService = {
  getReferenceQuestion, //Get Reference Question
  saveReferenceAnswer, //save refernece answer
};

export default referenceService;
