import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

export default function Header() {
  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={3} mt={3} mb={3}>
            <img alt="logo" width={"100%"} src="/logo_wide.png" />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
