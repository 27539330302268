import React, { useState } from "react";
import Container from "@mui/material/Container";

import { Box, Button, Typography } from "@mui/material";

//reference service
import ReferenceService from "../../../services/reference.service";

function Questionnaire({ questions, id, thankyou }) {
  //current question index
  const [currentQuestion, setcurrentQuestion] = useState(1);

  //save questions list
  const [savedAnswers, setsavedAnswers] = useState([]);

  //next button handling function
  const handleNext = () => {
    setcurrentQuestion(currentQuestion + 1);
  };

  //previous button handling button
  const handlePrevious = () => {
    setcurrentQuestion(currentQuestion - 1);
  };

  //saving answers to api
  function saveThisAnswers() {
    //tempropry array
    let saved_temp = [];

    //beautify array for john's requirement
    savedAnswers.forEach((single) => {
      let answer;
      if (Array.isArray(single.answer)) {
        answer = single.answer.join(", ");
      } else {
        answer = single.answer;
      }

      saved_temp.push({
        question: single.question,
        question_type: single.question_type,
        answer: answer,
      });
    });

    //final data to be sent to api
    let data_to_send = {
      unique_id: id,
      question_list: saved_temp,
    };

    //saving answers to api
    ReferenceService.saveReferenceAnswer(data_to_send).then(
      (response) => {
        //setting thank you page when success
        thankyou(true);
      },
      (error) => {
        console.log("error ", error);
      }
    );
  }

  //progress bar function - this will highlight it
  const renderProgressBar = () => {
    return (
      <Box display="flex" alignItems="center" mb={5} mt={3}>
        {questions.map((single, index) => {
          return (
            <div key={index}>
              {single.id === currentQuestion || single.id < currentQuestion ? (
                <Box
                  key={index}
                  bgcolor="#398ACA"
                  width={57}
                  height={4}
                  marginRight={2}
                  flexGrow={1}
                />
              ) : (
                <Box
                  key={index}
                  bgcolor="lightgrey"
                  width={57}
                  height={4}
                  marginRight={2}
                  flexGrow={1}
                />
              )}
            </div>
          );
        })}
      </Box>
    );
  };

  //save answers locally
  function saveAnswersLocal(questionId, answer, type) {
    if (type === "Multiple Choice") {
      const selectedQuestion = questions.find(
        (single) => single.id === questionId
      );
      const existingQuestion = savedAnswers.find(
        (saved) => saved.id === questionId
      );

      if (!existingQuestion) {
        selectedQuestion.answer = [answer];
        setsavedAnswers((prevState) => [...prevState, selectedQuestion]);
      } else {
        if (existingQuestion.answer.includes(answer)) {
          existingQuestion.answer = existingQuestion.answer.filter(
            (a) => a !== answer
          );
        } else {
          existingQuestion.answer = [...existingQuestion.answer, answer];
        }
        setsavedAnswers((prevState) => [...prevState]);
      }
    } else {
      const selectedQuestion = questions.find(
        (single) => single.id === questionId
      );
      const existingQuestion = savedAnswers.find(
        (saved) => saved.id === questionId
      );

      if (!existingQuestion) {
        selectedQuestion.answer = answer;
        setsavedAnswers((prevState) => [...prevState, selectedQuestion]);
      } else {
        existingQuestion.answer = answer;
        setsavedAnswers((prevState) => [...prevState]);
      }
    }
  }

  //render questions ui
  const renderQuestion = () => {
    const single = questions.find((single) => single.id === currentQuestion);
    return (
      <Box>
        <>
          {renderProgressBar()}

          <Typography
            variant="h5"
            fontFamily="Poppins"
            style={{ textAlign: "center", color: "#131313" }}
          >
            {single.question}
          </Typography>

          {/* <Typography variant="h6" fontFamily="Poppins" style={{ textAlign: "center", color: "#131313", fontSize: 12 }}>
                        { single.answer }
                    </Typography> */}

          <Box mt={4}>
            {single.choices.map((choice, index) => {
              if (typeof single.answer === "object") {
                return (
                  <Box
                    key={index}
                    onClick={() =>
                      saveAnswersLocal(single.id, choice, single.question_type)
                    }
                    pl={3}
                    pr={3}
                    pt={2}
                    pb={2}
                    mb={2}
                    style={{
                      border: single.answer.find((a) => a === choice)
                        ? "2px solid #398ACA"
                        : "2px solid #F7F7F7",
                      borderRadius: 4,
                      cursor: "pointer",
                      backgroundColor: single.answer.find((a) => a === choice)
                        ? "rgba(57, 138, 202, 0.08)"
                        : "#F7F7F7",
                    }}
                  >
                    <Typography fontFamily="Poppins">{choice}</Typography>
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={index}
                    onClick={() =>
                      saveAnswersLocal(single.id, choice, single.question_type)
                    }
                    pl={3}
                    pr={3}
                    pt={2}
                    pb={2}
                    mb={2}
                    style={{
                      border:
                        single.answer === choice
                          ? "2px solid #398ACA"
                          : "2px solid #F7F7F7",
                      //border: "2px solid #F7F7F7",
                      borderRadius: 4,
                      cursor: "pointer",
                      //backgroundColor: '#F7F7F7'
                      backgroundColor:
                        single.answer === choice
                          ? "rgba(57, 138, 202, 0.08)"
                          : "#F7F7F7",
                    }}
                  >
                    <Typography fontFamily="Poppins">{choice}</Typography>
                  </Box>
                );
              }
            })}
          </Box>
        </>
      </Box>
    );
  };

  //original component return
  return (
    <Container maxWidth="sm">
      <Box>
        <Typography
          variant="h4"
          fontFamily="Poppins"
          style={{
            fontSize: 30,
            fontWeight: 600,
            textAlign: "center",
            color: "#131313",
          }}
          mb={5}
        >
          Recommendation Request Form
        </Typography>
        {renderQuestion()}

        <Box mt={10} display="flex" justifyContent="space-between">
          <Button
            variant="outlined"
            disabled={currentQuestion === 1}
            onClick={handlePrevious}
          >
            Back
          </Button>
          {currentQuestion === questions.length ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveThisAnswers()}
              >
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={
                  questions.find((single) => single.id === currentQuestion)
                    .answer === ""
                }
              >
                Next
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Container>
  );
}

export default Questionnaire;
