import { Box } from "@mui/material";
import React from "react";

export const Home = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img alt="logo" width={"100%"} src="/logo_wide.png" />
      </Box>
    </Box>
  );
};
