import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Header from "components/Header/Header";
import { Typography } from "@mui/material";

//questions looping file
import Questions from "./Questions/Index";
//API service file
import ReferenceService from "../../services/reference.service";

export default function Index() {
  const { id } = useParams();

  //intro mode
  const [introMode, setintroMode] = useState(true);

  //already filled mode
  const [filledAlready, setfilledAlready] = useState(false);

  //thak you mode
  const [thankyou, setThankYouMode] = useState(false);

  //invalid request
  const [invalidrequst, setinvalidrequst] = useState(false);

  //loading state
  const [loading, setloading] = useState(true);

  //list of questions
  const [list, setList] = useState([]);

  //candidate name
  const [candidate, setcandidate] = useState("");

  //get reference questions list from api
  useEffect(() => {
    ReferenceService.getReferenceQuestion(id).then(
      (response) => {
        if (response === "invalid_unique_id") {
          setloading(false);
          setinvalidrequst(true);
        } else {
          if (response === "Already Completed") {
            setloading(false);
            setfilledAlready(true);
          } else {
            let temp_list = [];
            setcandidate(response.candidate_name);
            response.questions.forEach((single, index) => {
              temp_list.push({ id: index + 1, ...single });
            });
            setList(temp_list);
            setloading(false);
          }
        }
      },
      (error) => {
        console.log("error ", error);
        //window.location.replace('/')
      }
    );
  });

  //while loading show nothing
  if (loading) {
    return null;
  }

  //ui component of this file
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Grid container spacing={2} mt={5}>
          {invalidrequst ? (
            <>
              <Grid item xs={12} mt={20} mb={3}>
                <Typography
                  variant="h3"
                  fontFamily="Poppins"
                  style={{
                    fontWeight: "600",
                    textAlign: "center",
                    color: "#131313",
                  }}
                >
                  Sorry your request is invalid.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              {thankyou ? (
                <>
                  <Grid
                    item
                    xs={12}
                    mt={20}
                    mb={3}
                    style={{ textAlign: "center" }}
                  >
                    <Typography
                      variant="h3"
                      fontFamily="Poppins"
                      style={{
                        fontWeight: "600",
                        textAlign: "center",
                        color: "#131313",
                      }}
                    >
                      Thank you for providing your reference!
                    </Typography>
                    <Typography
                      variant="p"
                      fontFamily="Poppins"
                      style={{
                        marginTop: 20,
                        display: "block",
                        textAlign: "center",
                        color: "#131313",
                      }}
                    >
                      You can close this tab now
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  {introMode ? (
                    <>
                      {filledAlready ? (
                        <>
                          <Grid item xs={12} mt={20} mb={3}>
                            <Typography
                              variant="h3"
                              fontFamily="Poppins"
                              style={{
                                fontWeight: "600",
                                textAlign: "center",
                                color: "#131313",
                              }}
                            >
                              I’m sorry, you’ve already filled this form.
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} mt={20} mb={3}>
                            <Typography
                              variant="h3"
                              fontFamily="Poppins"
                              style={{
                                fontWeight: "600",
                                textAlign: "center",
                                color: "#131313",
                              }}
                            >
                              <span style={{ color: "#398aca" }}>
                                {candidate}
                              </span>{" "}
                              has asked you to fill out this reference
                              recommendation form
                            </Typography>

                            <Box style={{ textAlign: "center" }} mt={5}>
                              <Button
                                variant="contained"
                                size="large"
                                onClick={() => setintroMode(false)}
                              >
                                Get Started
                              </Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Questions
                        questions={list}
                        id={id}
                        thankyou={() => setThankYouMode(true)}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Container>
    </>
  );
}
